import { useRequest } from '@zupr/hooks/request-redux'
import {
    Action,
    Config,
    Field,
    FieldConfig,
    FieldConfigs,
    Options,
} from '@zupr/types/form'
import { merge as deepMerge, get as dotGet } from 'lodash'
import { useCallback, useMemo } from 'react'

interface UseConfigProps {
    url?: string
    fields?: FieldConfigs
}

interface UseConfigResult {
    fetching?: boolean
    config: Config
    action?: 'PUT' | 'POST' // POST is new item, PUT is update
    availableFields: Field['name'][]
    requiredFields: Field['name'][]
    getFieldConfig: (name: Field['name']) => (FieldConfig & Action) | undefined
}

// fetches options from api and merges with local field configuration
const useConfig = ({ url, fields }: UseConfigProps): UseConfigResult => {
    const [options, request] = useRequest<Options>({
        url,
        pause: !url,
        method: 'OPTIONS',
    })

    // merge fields from props with fields from api
    const config = useMemo(() => {
        const actions = options?.actions?.PUT || options?.actions?.POST || {}
        return deepMerge(actions, fields || {})
    }, [options, fields])

    const extractAvailableFields = useCallback((config: Config) => {
        let available: string[] = []

        // add available field for this level
        Object.keys(config)
            .filter((name) => !config[name].children) // has no children (children itself will be added)
            .filter((name) => !name.startsWith('_')) // does not start with _
            .filter((name) => !config[name].read_only) // is not read only
            .map((name) => available.push(name))

        // add available field for children
        Object.keys(config)
            .filter((name) => !name.startsWith('_')) // does not start with _
            .filter((name) => !config[name].read_only) // is not read only
            .forEach((name) => {
                const children = config[name].children
                if (!children) return
                extractAvailableFields(children)
                    .map((child) => `${name}.${child}`)
                    .map((name) => available.push(name))
            })

        return available.sort()
    }, [])

    const getFieldConfig: UseConfigResult['getFieldConfig'] = useCallback(
        (name) => {
            return dotGet(config, name.replace(/\./g, '.children.'))
        },
        [config]
    )

    const availableFields = useMemo(
        () => extractAvailableFields(config),
        [config, extractAvailableFields]
    )

    const requiredFields = useMemo(
        () => availableFields.filter((name) => getFieldConfig(name)?.required),
        [availableFields, getFieldConfig]
    )

    // action is undefined until we fetch options
    let action = undefined
    if (options?.actions) {
        action = !options.actions.POST ? 'PUT' : 'POST' // some endpoint have put AND post actions, only when post is not available we use put
    }

    return useMemo<UseConfigResult>(
        () => ({
            action,
            config,
            availableFields,
            requiredFields,
            getFieldConfig,
            fetching: request.fetching,
        }),
        [
            action,
            config,
            availableFields,
            requiredFields,
            getFieldConfig,
            request.fetching,
        ]
    )
}

export default useConfig
